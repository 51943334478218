export function getCampaignDetails() {
  const campaignDetails = []

  if (typeof window !== 'undefined' && window.Insider) {
    const campaign = window.Insider?.campaign
    const shownCampaigns = campaign?.shownCampaigns
    const activeCampaigns = Object.keys(shownCampaigns || {})
    if (activeCampaigns.length === 0) {
      return []
    }

    for (const activeCampaign of activeCampaigns) {
      const builderId = campaign?.getBuilderIdByVariationId(activeCampaign)
      const variationNameEncrypted = shownCampaigns?.[activeCampaign]?.cn
      const variationName = campaign?.decryptCampaignName(
        variationNameEncrypted
      )

      campaignDetails.push({
        builderId,
        variationName,
        variationId: activeCampaign,
      })
    }
  }

  return campaignDetails
}

export function getAndSetInsiderCampaignEvents(eventType) {
  const insiderCampaignEvents = []

  const campaignDetails = getCampaignDetails()
  if (!campaignDetails || !campaignDetails.length) {
    return []
  }

  for (const campaignDetail of campaignDetails) {
    const { builderId, variationName, variationId } = campaignDetail
    if (!builderId || !variationName || !variationId) {
      continue
    }

    const isControlCampaign =
      window.Insider?.campaign?.isControlGroup(variationId)
    const partnerId = window.Insider?.partner?.partnerId
    const isMobile = window.Insider?.browser?.isMobile()
    const campaign = variationName.split('|')
    const experiment = campaign?.[0]?.trim()
    const variation = campaign?.[1]?.trim()

    insiderCampaignEvents.push({
      event_type: eventType,
      experiment_id: builderId,
      experiment_name: experiment,
      variation: variation,
      additional_info: {
        isControlCampaign,
        variationId,
        partnerId,
        isMobile,
      },
    })
  }

  return insiderCampaignEvents
}
