import { ABExperiments, ABExperimentVariations } from './consts'

export const hostNameToTealiumEnvMap = {
  localhost: 'dev',
  'fdr.dev.fdrgcp.com': 'dev',
  'fdr.stg.fdrgcp.com': 'qa',
  'fdr.sbx.fdrgcp.com': 'qa',
  'fdr.prd.fdrgcp.com': 'prod',
  'www.freedomdebtrelief.com': 'prod',
}

//TODO remove getTealiumEnvPath
export const getTealiumEnvPath = (host) => {
  const currentEnv = hostNameToTealiumEnvMap[host]

  return {
    script_path: `https://tags.freedomdebtrelief.com/fdr-web/${currentEnv}/utag.js`,
    currentEnv,
  }
}

export const getPageName = (asPath) => {
  const path = asPath.slice(1, -1).split('/')
  const lowerCaseStrings = path.shift().split('-')

  const upperCaseStrings = lowerCaseStrings.map((item) => {
    return item.charAt(0).toUpperCase() + item.slice(1)
  })

  return upperCaseStrings[0] === '' ? 'Home' : upperCaseStrings.join(' ')
}

export function getTitleFromPath(path) {
  const segments = path.split('/')

  const lastPartWithoutQueryParams = path
    .split('?')[0]
    ?.match(/\/([^\/]+)\/?$/)[1]

  const isValidPageTitle = lastPartWithoutQueryParams !== '#'

  const title =
    (segments.includes('l') && segments.includes('page')) ||
    (segments.includes('blog') && segments.includes('page')) ||
    (segments.includes('author') && segments.includes('page'))
      ? segments[segments.length - 4]
          .replaceAll('-', ' ')
          .replace(/(^#?\w|\s\w)/g, (m) => m.toUpperCase()) +
          ' ' +
          'Page-' +
          segments[segments.length - 2] ===
        '#'
        ? segments[segments.length - 3]
        : segments[segments.length - 2]
      : isValidPageTitle
      ? lastPartWithoutQueryParams
          .replaceAll('-', ' ')
          .replace(/(^#?\w|\s\w)/g, (m) => m.toUpperCase())
      : path
          .split('?')[0]
          .split('/')
          .filter((el) => el)
          .slice(-2)[0]
          .replaceAll('-', ' ')
          .replace(/(^#?\w|\s\w)/g, (m) => m.toUpperCase())

  const isHomePage = new RegExp(
    '(localhost:7000|(freedomdebtrelief|(fdr.(dev|stg|sbx|prd).fdrgcp)).com)',
    'i'
  )

  return isHomePage.test(title) ? 'Homepage' : title
}

export const getScrollPercentage = () => {
  let windowHeight =
    window.innerHeight ||
    (document.documentElement || document.body).clientHeight
  let docHeight = Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight
  )
  let trackLength = docHeight - windowHeight
  const scrollPrc = Math.round((window.scrollY / trackLength) * 100)

  switch (true) {
    case scrollPrc < 25:
      return 0
    case scrollPrc < 50:
      return 25
    case scrollPrc < 75:
      return 50
    case scrollPrc < 90:
      return 75
    case scrollPrc < 100:
      return 90
    default:
      return 100
  }
}

export const ABExperimentsEnum = (variation, page, experimentSection) => {
  const { experiment_id, experiment_name } =
    ABExperiments[experimentSection || page] || {}

  if (!experiment_id) {
    return {
      experiment_id: null,
      experiment_name: null,
      variationData: { name: 'control', label: 'control' },
    }
  }

  return {
    experiment_id,
    experiment_name,
    variationData: ABExperimentVariations[experiment_id]?.[variation] ||
      ABExperimentVariations[experiment_id]?.default || {
        name: 'control',
        label: 'Control',
      },
  }
}

export const createExperiment = (options) => {
  const { variation, eventType, pageTitle, experimentSection } = options
  const { variationData, experiment_id, experiment_name } = ABExperimentsEnum(
    variation,
    pageTitle,
    experimentSection
  )

  return {
    event_type: eventType || 'conversion',
    experiment_id: experiment_id,
    experiment_name: experiment_name,
    variation: variationData.name,
    variation_name: variationData.label,
  }
}

export const getVariationExperiments = ({
  variations,
  isCrawler,
  eventType,
}) => {
  // TODO: Investigate why the Contact and How It Works pages have isCrawler set to true.
  /* if (isCrawler) {
    return []
  } else {
    const experiments = []
    variations.map((variation) => {
      if (variation?.variation && variation?.variation !== 'off') {
        const experiment = createExperiment({
          variation: variation.variation,
          eventType: eventType,
          experimentSection: variation.experimentSection,
        })
        if (experiment.experiment_id) {
          experiments.push(experiment)
        }
      }
    })

    return experiments
  } */

  const experiments = []
  variations.map((variation) => {
    if (variation?.variation && variation?.variation !== 'off') {
      const experiment = createExperiment({
        variation: variation.variation,
        eventType: eventType,
        experimentSection: variation.experimentSection,
      })
      if (experiment.experiment_id) {
        experiments.push(experiment)
      }
    }
  })

  return experiments
}

export const getCustomTitleByVariant = (homeVariation = '') => {
  if (homeVariation.toLowerCase().replace(/\s/g, '') === 'variant7') {
    return 'Variation A'
  }

  return homeVariation
}

export const getCustomPathnameByVariant = (pathname) => {
  const newPathname = pathname.replace(/\s/g, '').split(':')

  if (newPathname[1] === 'variant-7') {
    return `${newPathname[0]}: variation_a`
  }

  return pathname
}
