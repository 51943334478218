'use client'

// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS } from '@contentful/rich-text-types'

export function hasFileExtension(path) {
  return /^.*\.(jpg|jpeg|png|gif|svg|doc|pdf|xml|xlsx)$/i.test(path)
}

export const isNativePath = (path = '') => {
  const relativePathRegExp = new RegExp(
    '^(?:https?:\\/\\/)?((?:www.)?(freedomdebtrelief|(fdr.(dev|stg|sbx|prd).fdrgcp))).com',
    'i'
  )

  return relativePathRegExp.test(path)
}

export const beautifyUrlPath = (path, trailingSlash = true) => {
  let pathToFix = hasFileExtension(path) ? `/${path}` : `/${path}/`

  pathToFix = pathToFix.replace(/\/+/g, '/')

  return trailingSlash ? pathToFix : pathToFix.replace(/^(.+?)\/*?$/, '$1')
}

export function fullUrl(path = '') {
  let finalPath = path

  const isNative = isNativePath(path)

  if (isNative) {
    finalPath = `/${path.replace(
      new RegExp(
        '^(?:https?:\\/\\/)?((?:www.)?(freedomdebtrelief|(fdr.(dev|stg|sbx|prd).fdrgcp))).com'
      ),
      ''
    )}/`
  }

  return beautifyUrlPath(finalPath)
}

export const isPathRelative = (path = '') => {
  const relativePathRegExp = new RegExp('^(http|ftp|tel|mailto|//)', 'i')

  return !relativePathRegExp.test(path)
}

export const removeCurrentDomain = (path = '') => {
  const internalPathRegExp = new RegExp(
    '^(?:https?:\\/\\/)?((((?:www\\.)?(freedomdebtrelief|(fdr.(dev|stg|sbx|prd).fdrgcp)))\\.com)|(localhost:\\d+))',
    'i'
  )

  if (internalPathRegExp.test(path)) {
    return `${path.replace(internalPathRegExp, '')}`
  }

  return path
}

export function formatUrl(path = '') {
  const finalPath = removeCurrentDomain(path)

  return isPathRelative(finalPath) ? beautifyUrlPath(finalPath) : path
}

export function addScriptToHead(url, id) {
  let script = document.getElementById(id)
  if (!script) {
    script = document.createElement('script')
    script.setAttribute('id', id)
    script.src = url
    script.async = true
    document.head.appendChild(script)
  }

  return script
}

// Node Utils

export function generateRandomKey() {
  return Math.random().toString(36).substr(2, 9)
}

export const defaultPhoneNumber = '800-910-0065'
export const controlPhone = '833-582-2310'
export const testPhone = '833-582-7700'

export const newControlPhone = '833-418-3210'
export const newTestPhone = '833-582-2310'

export function numberFormat(value, options) {
  const opts = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  }
  const val = Intl.NumberFormat('en-US', opts).format(value)

  return val === 'NaN' ? '' : val
}

export function formatDate(date, options = {}) {
  if (!Date.parse(date)) {
    return ''
  }

  const {
    locale = 'en-US',
    year = 'numeric',
    month = 'long',
    day = 'numeric',
    timeZone = 'America/Los_Angeles',
  } = options

  return new Date(`${date}-1200`).toLocaleDateString(locale, {
    year,
    month,
    day,
    timeZone,
  })
}

export function getFirstNameFromFullName(name) {
  return name?.split(' ')[0] || ''
}

export function isNumber(value) {
  const numberRegex = /^\d+$/

  return numberRegex.test(value)
}

export function extractYoutubeID(url) {
  // eslint-disable-next-line
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
  const match = url.match(regExp)

  return match && match[7].length === 11 ? match[7] : ''
}

export function insertItem(arr, index = 0, newItem) {
  return [...arr.slice(0, index), newItem, ...arr.slice(index)]
}

export async function findPaymentReferenceIndex(
  paymentsReferenceTableKeys,
  amount
) {
  let paymentReferenceIndex = -1

  for (let i = 0; i < paymentsReferenceTableKeys.length; i++) {
    if (paymentsReferenceTableKeys[i] === amount) {
      paymentReferenceIndex = i
      break
    } else if (
      paymentsReferenceTableKeys[i] < amount &&
      i + 1 < paymentsReferenceTableKeys.length &&
      paymentsReferenceTableKeys[i + 1] > amount
    ) {
      paymentReferenceIndex = i
      break
    }
  }

  return paymentReferenceIndex
}

export function relatedPostsConverter(content, similarPosts = []) {
  let contentList = [...content]

  const relatedPosts = {
    nodeType: 'related-posts',
    content: [],
    data: { similarPosts },
  }
  const heading2 = content.filter((el) => el.nodeType === BLOCKS.HEADING_2)
  if (heading2.length > 1) {
    const relatedPostPosition = contentList.indexOf(heading2[1])
    contentList = insertItem(content, relatedPostPosition, relatedPosts)
  } else if (heading2.length === 1) {
    const relatedPostPosition = contentList.indexOf(heading2[0])
    contentList = insertItem(content, relatedPostPosition, relatedPosts)
  } else {
    contentList = insertItem(content, 0, relatedPosts)
  }

  return contentList
}

export function findClosestHeadingToHyperlink(hyperlinkRef) {
  let closestHeading = null
  let closestDistance = Infinity
  const allHeadings = document.querySelectorAll('h1, h2, h3, h4')

  allHeadings.forEach((heading) => {
    if (heading.id && hyperlinkRef.current) {
      const distance = hyperlinkRef.current.offsetTop - heading.offsetTop

      if (distance < closestDistance && distance > 0) {
        closestDistance = distance
        closestHeading = heading
      }
    }
  })

  return closestHeading?.innerText || ''
}

export function getModifiedDate(updatedAt, publishedDate) {
  const updated = new Date(updatedAt.split('T')[0])
  const published = new Date(publishedDate.split('T')[0])

  return updated.getTime() <= published.getTime()
    ? null
    : updated.toLocaleString()
}

export function toJsonLdDate(dateString) {
  return new Date(dateString).toString()
}

export const buildQueryString = (baseQuery, userKey, cookies) => {
  let leadId = userKey ? encodeURIComponent(userKey) : undefined
  const urlParams = {
    from: 'fdr',
    utm_source: baseQuery['utm_source'],
    leadId,
    visit_id: cookies?.eh_visit_id || null,
  }

  for (let key in urlParams) {
    if (!urlParams[key]) {
      delete urlParams[key]
    }
  }

  const q = new URLSearchParams(urlParams)

  return q.toString()
}

export const parseCookies = (cookies = '') => {
  return cookies.split(/\s*;\s*/).reduce((result, cookie) => {
    const separatorIndex = cookie.indexOf('=')
    const key = cookie.substring(0, separatorIndex)
    const value = decodeURIComponent(cookie.substring(separatorIndex + 1))

    return { ...result, [key]: value }
  }, {})
}

export const getPhoneNumber = (isHome, homepageDropDownFloatingFooter) => {
  if (isHome) {
    if (homepageDropDownFloatingFooter === 'off') {
      return defaultPhoneNumber
    }

    return homepageDropDownFloatingFooter === 'test'
      ? newTestPhone
      : newControlPhone
  }

  return defaultPhoneNumber
}
