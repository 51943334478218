import React, { useEffect } from 'react'
import { useAnalytics } from '.'
import { usePathname } from 'next/navigation'
import axios from 'axios'
import { getCookie, setCookie } from 'cookies-next'

export const PznTracker = ({ visitId }) => {
  const { track } = useAnalytics()
  const pathname = usePathname()

  const fetchPznInformation = async () => {
    try {
      const browserId = getCookie('browser_id')
      const evVisitId = getCookie('eh_visit_id')

      const { data } = await axios.post('/api/perzonalizate', {
        visitId: evVisitId,
        browserId,
      })

      const { response = {} } = data
      const trakingData = {
        additional_info: {
          section: 'perzonalization-view',
          campaing_id: 'fdr_pzn_organic',
          path: pathname,
        },
        track_event: 'section_view',
        event_type: 'track-pzn',
        segment: response?.lead?.segment || null,
        lead_days: response?.lead?.lead_days || null,
        profile_id: response?.lead?.profile_id || null,
        lead_stage: null,
        lead_type: response?.lead?.flow_name || null,
      }
      track(trakingData, {}, 'section_view')
    } catch (error) {
      console.log('error personalization ------', error)
    }
  }

  useEffect(() => {
    const cookieIndex = 'pzn-svc'
    const pznSvcCookie = getCookie(cookieIndex)
    const today = new Date()
    if (!pznSvcCookie) {
      // add a 24hrs
      today.setDate(today.getDate() + 1)
      fetchPznInformation()
      setCookie(cookieIndex, today.getTime())
    } else {
      const expirationTime = new Date(pznSvcCookie).getTime()
      if (expirationTime > today.getTime()) {
        fetchPznInformation()
      }
    }
  }, [visitId])

  return null
}
