import getConfig from 'next/config'
import { getCookeByKey } from '../helpers'
import { getVariationExperiments } from './helpers'
import { createAnalytics, plugins } from '@achieve/cx-event-horizon/browser'
import { getAndSetInsiderCampaignEvents } from '../utils/insider'

const {
  NEXT_PUBLIC_EVENT_HORIZON_APP_NAME,
  NEXT_PUBLIC_EVENT_HORIZON_DEBUG,
  NEXT_PUBLIC_EVENT_HORIZON_TRACKER_URL,
} = getConfig()?.publicRuntimeConfig
const isDebug = NEXT_PUBLIC_EVENT_HORIZON_DEBUG

const analyticsInstance = createAnalytics({
  application: NEXT_PUBLIC_EVENT_HORIZON_APP_NAME,
  debug: isDebug,

  plugins: [
    plugins.analyticsFreedom({
      application: NEXT_PUBLIC_EVENT_HORIZON_APP_NAME,
      isDebug: isDebug,
      trackingUrl: NEXT_PUBLIC_EVENT_HORIZON_TRACKER_URL ?? '',
      // Note: The trackingUrl is used for local testing of events to ensure they are being sent as expected.
      // In deployed environments, the Event Horizon library automatically handles the trackingUrl value.
      // For more details, refer to the Event Horizon documentation:
      // https://billsdev.atlassian.net/wiki/spaces/CXTOOL/pages/2639594296/Event+Horizon#2.-Initialize-and-Export-Analytics
    }),
    plugins.analyticsTealium({
      application: NEXT_PUBLIC_EVENT_HORIZON_APP_NAME,
    }),
  ],
})

export const trackView = async (options) => {
  await identifyUser()

  const {
    pageTitle,
    currentEnv,
    urlData,
    pathname,
    href,
    referringUrl,
    statusCode,
    isCrawler,
    additional_info,
    dataVisualization: variation_cell,
    experimentSection,
    variations,
  } = options
  const isNotFound = statusCode === 404

  const pageData = {
    event_origin: 'client',
    event_action: 'page_view',
    page_name: `FDR-Web | ${isNotFound ? 'Page Not Found' : pageTitle}`,
    event_name: `FDR-Web | ${
      isNotFound ? 'Page Not Found' : pageTitle
    } - Page Loaded`,
    application_data: {
      application_version: currentEnv,
      url_params: urlData.urlParams,
      page_title: isNotFound ? 'Page Not Found' : pageTitle,
      page_path: `FDR-Web | ${pathname}`,
      page_url: urlData.path,
      full_page_url: href,
      referring_url: referringUrl,
      additional_info,
      variation_cell,
    },
  }

  pageData.experiments = getVariationExperiments({
    variations,
    isCrawler,
    eventType: 'start',
  })

  analyticsInstance.page(pageData)
}

export const trackEvent = async (options) => {
  await identifyUser()

  const {
    tealiumData,
    experimentData,
    event_origin = 'client',
    currentEnv,
    urlData,
    pageTitle,
    pathname,
    href,
    referringUrl,
    statusCode,
    isCrawler,
    variations = [],
    action,
  } = options

  const variation_cell =
    experimentData.experimentSection === 'dataVisualization'
      ? { variation_cell: experimentData.variation }
      : {}

  const isNotFound = statusCode === 404
  const eventAction = action || tealiumData?.event_action || 'click'

  let trackData = {
    event_origin,
    action: eventAction,
    event_action: eventAction,
    event_type: tealiumData?.event_type || 'track',
    page_name: `FDR-Web | ${isNotFound ? 'Page Not Found' : pageTitle}`,
    application_data: {
      application_version: currentEnv,
      url_params: urlData.urlParams,
      page_title: isNotFound ? 'Page Not Found' : pageTitle,
      page_path: `FDR-Web | ${pathname}`,
      page_url: urlData.path,
      full_page_url: href,
      referring_url: referringUrl,
      additional_info: experimentData.additional_info || {},
      ...variation_cell,
      ...tealiumData,
    },
  }

  const experiments = getVariationExperiments({
    variations,
    isCrawler,
    eventType: experimentData?.event_type || tealiumData?.event_type,
  })

  experiments.concat(
    getAndSetInsiderCampaignEvents(tealiumData?.event_type || 'track')
  )

  trackData.experiments = experiments

  analyticsInstance.track('Continue Clicked', trackData)
}

export const getLeadId = () => {
  if (typeof window !== 'undefined') {
    return getCookeByKey(document.cookie, 'leadId')
  }
}

let isIdentified
const identifyUser = async () => {
  const id = getLeadId()
  if (!isIdentified && id) {
    await analyticsInstance.identify(id)
    isIdentified = true
  }
}

export const trackScroll = (options) => {
  analyticsInstance.track('Page Scrolled', options)
}

export const getDataVisualizationByHomeExperiment = (homeVariation) => {
  if (homeVariation !== 'control' && homeVariation !== 'control_ny')
    return 'control'

  return homeVariation !== 'control_ny'
    ? 'control_with_bar_chart'
    : 'hide_data_visualization'
}
