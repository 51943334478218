import React, { createContext, useContext } from 'react'

export const TrustMarkersContext = createContext({})

export const TrustMarkersProvider = React.memo(
  ({ children, trustMarkers = [] }) => {
    const getAllTrustMarkers = () => trustMarkers
    const getTrustMarkerById = (id) =>
      trustMarkers.find((trustMarker) => trustMarker?.id?.toLowerCase() === id)

    return (
      <TrustMarkersContext.Provider
        value={{ getAllTrustMarkers, getTrustMarkerById }}
      >
        {children}
      </TrustMarkersContext.Provider>
    )
  }
)

export const useTrustMarkers = () => useContext(TrustMarkersContext)

TrustMarkersProvider.displayName = 'TrustMarkersProvider'
